<template>
  <!--srcviewscompanydetailsBusinessActivitiesSupplyChainvuehtmlStart-->

  <div id="defaultId1 " style="padding-bottom:20px">

    <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false" >
      <div>
        <h2 class="operations-head flex_center_between_box margin-bottom20">
          <span class="head-titel detail_title_20">Country Ratings</span>
      
        </h2>
      <div class="flex_center_between_box">
        <div style="width: 48%;">
          <p class="CountryUsd" style="border-top: 1px solid #ccc;"><span class="CountryUsdspan">Competitiveness Index:</span>{{ CountryObject?.competitivenessIndex }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Competitiveness Rank:</span>{{ CountryObject?.competitivenessRank  }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Ease of Doing Business:</span>{{ CountryObject?.easeOfDoingBusiness  }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Legal Environment:</span>{{ CountryObject?.legalEnvironment }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Business Confidence:</span>{{ CountryObject?.businessConfidence }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Consumer Confidence:</span>{{ CountryObject?.consumerConfidence }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Business Climate Assessment:</span>{{ CountryObject?.businessClimateAssessment }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">S&P:</span>{{ CountryObject?.sp }}</p>
        </div>
        <div style="width: 48%;">
          <p class="CountryUsd" style="border-top: 1px solid #ccc;"><span class="CountryUsdspan">Moody's:</span>{{ CountryObject?.moody }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Fitch:</span>{{ CountryObject?.fitch }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">DBRS:</span>{{ CountryObject?.dbrs }}</p>
          <p class="CountryUsd" ><span class="CountryUsdspan">Allianz:</span>{{ CountryObject?.allianz }}</p>
          <p class="CountryUsd" ><span class="CountryUsdspan">Coface:</span>{{ CountryObject?.coface }}</p>
          <p class="CountryUsd" ><span class="CountryUsdspan">Atradius:</span>{{ CountryObject?.atradius }}</p>
          <p class="CountryUsd" ><span class="CountryUsdspan">OECD:</span>{{ CountryObject?.oecd }}</p>
          <p class="CountryUsd" ><span class="CountryUsdspan"></span></p>
        </div>
      </div>

      </div>
    </gui_card>

    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
</template>

<script>
import { showCountryRatings, } from '@/api/companyApi.js';
import rectcard from "@/components/rectCard/index.vue";
import content_text from "@/components/text_content/index.vue";
import ReLogin from "../../../../components/ReLogin.vue";
import gui_card from "@/components/gui_card/gui_card.vue";
import { nextTick } from 'vue';
export default {
  name: "",
  // props: {},
  components: {
    gui_card,
    // gui_card end

    rectcard,
    content_text,
    ReLogin,
  },
  data() {
    return {
  

 
      datePicker: [],
      dateImportPicker: [],

   CountryObject:{
    allianz:'',
    atradius:'',
    businessClimateAssessment:'',
    businessConfidence:'',
    coface:'',
    competitivenessIndex:'',
    consumerConfidence:'',
    competitivenessRank:'',
    consumerConfidence:'',
    dbrs:"",
    easeOfDoingBusiness:'',
    fitch:'',
    legalEnvironment:'',
    moody:'',
    oecd:'',
    sp:''

   },
      importTableData: [],
      reLoginVis: false,

    };
  },
  mounted() {
    this.getmportData();

  },
  methods: {
  

    ChangeEcportDate() {

      this.getexportData()
    },


    getmportData() {
      showCountryRatings({
        country:'US'

      }).then(res => {
        if (res.code == 200) {
          this.CountryObject = res.data
        }
      })


    },
  

  },
  computed: {},

};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}

.importDetail-head {
  display: flex;
}

.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;
}

.table-biao {
  height: auto;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 0.2857142857142857rem;
  margin-top: 1.4285714285714286rem;
}

.operations-head {
  font-size: 2.142857142857143rem;
  /* font-family: "Arial Bold"; */
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-family: 'DMSans Bold';
  padding-left: 1.4285714285714286rem !important;
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
  font-family: 'DMSans Bold';
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}

@media screen and (max-width: 1441px) {
  .contclass {
    font-size: 14px;

  }
}


.table_add {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_add_Text {
  font-family: 'DMSans Bold';
  text-align: left;
}

.table-content {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  display: inline-block;
  line-height: 2.142857142857143rem;
}

.uc1096 {
  height: 100%;
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.impact_status {
  width: 2.2857142857142856rem;
  height: 2.2857142857142856rem;
  text-align: center;
  border-radius: 50%;
}

.impact_status img {
  width: 1.1428571428571428rem;
  height: 1.1428571428571428rem;
  margin-top: 0.5rem;
}

.impact_status.green {
  background: #55b419;
}

.impact_status.hui {
  background: #ccc;
}

.impact_status.blue {
  background: #1290c9;
}

.impact_status.red {
  background: #ff7600;
}

.input-str {
  position: absolute;
  top: 0.2857142857142857rem;
  left: 13.214285714285714rem;
  z-index: 99;
  text-align: 7.142857142857143rem;
  width: 26%;
}

.input-str /deep/ .el-input__inner {
  margin-top: -0.14285714285714285rem;
}

/deep/.el-input__inner:focus {
  border: 0.07142857142857142rem solid #8497AB;
}

.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem;
}

.Products_lesft {
  width: 69%;
  margin-left: 3rem;
}

.table-biao {
  width: 33%;
}
.CountryUsd{
  width: 100%;
  height: 3.57142857rem;
  color: #1A2332;
  font-size: 1.285714rem;
  display: flex;
border-bottom: 1px solid #CCCCCC;
  align-items: center;
}
.CountryUsdspan{
width: 50%;
background: #F2F5F9;
font-size: 18px;
font-family: 'DMSans Bold' !important;
display: inline-block;
height: 100%;
display: flex;
align-items: center;

padding-left: 2.1428714286rem;
margin-right: 2.1428714286rem;
}
</style>
